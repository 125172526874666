import { ParallaxProvider } from "react-scroll-parallax";
import MainPage from "./main_page/MainPage";
import Navbar from "./main_page/containers/navigation/Navbar";

import { initializeApp } from "firebase/app";

// import firebaseConfig from "./firebase-config.json";
const firebaseConfig = {
  apiKey: "AIzaSyBF34iaS-D3Z-jL0fMbhAtVYS9e4jU2lLs",
  authDomain: "elk-resort-landingpage.firebaseapp.com",
  projectId: "elk-resort-landingpage",
  storageBucket: "elk-resort-landingpage.appspot.com",
  messagingSenderId: "135664630674",
  appId: "1:135664630674:web:431cc4e6086bac41c11f4b",
  measurementId: "G-BG5MH4K17B"
};
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <ParallaxProvider>
      <Navbar />
      <MainPage />
    </ParallaxProvider>
  );
}

export default App;
