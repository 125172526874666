import * as React from 'react';
import { scroller } from 'react-scroll';

import House, { HouseProps } from '../models/houses/House';
import DefaultHouseDetails from '../models/houses/DefaultHouseDetails';
import AmenityUrls from '../models/houses/amenities/AmenityUrls';

import LandingSection from '../assets/sections/LandingSection';
import OfferSection from '../assets/sections/OfferSection';
import AmenitiesSection from '../assets/sections/AmenitiesSection';
import ContactSection from '../assets/sections/ContactSection';

// Images
import house11 from '../images/house1/house11.jpg';
import house12 from '../images/house1/house12.jpg';
import house13 from '../images/house1/house13.jpg';
import house14 from '../images/house1/house14.jpg';
import house15 from '../images/house1/house15.jpg';
import house16 from '../images/house1/house16.jpg';
import house17 from '../images/house1/house17.jpg';
import house18 from '../images/house1/house18.jpg';
import house19 from '../images/house1/house19.jpg';
import house110 from '../images/house1/house110.jpg';
import house111 from '../images/house1/house111.jpg';
import house112 from '../images/house1/house112.jpg';

import house21 from '../images/house2/house21.jpg';
import house22 from '../images/house2/house22.jpg';
import house23 from '../images/house2/house23.jpg';
import house24 from '../images/house2/house24.jpg';
import house25 from '../images/house2/house25.jpg';
import house26 from '../images/house2/house26.jpg';
import house27 from '../images/house2/house27.jpg';
import house28 from '../images/house2/house28.jpg';
import house29 from '../images/house2/house29.jpg';
import house210 from '../images/house2/house210.jpg';
import house211 from '../images/house2/house211.jpg';
import house212 from '../images/house2/house212.jpg';
import house213 from '../images/house2/house213.jpg';

import house31 from '../images/house3/house31.jpg';
import house32 from '../images/house3/house32.jpg';
import house33 from '../images/house3/house33.jpg';
import house34 from '../images/house3/house34.jpg';
import house35 from '../images/house3/house35.jpg';
import house36 from '../images/house3/house36.jpg';
import house37 from '../images/house3/house37.jpg';
import house38 from '../images/house3/house38.jpg';

import quad1 from '../images/quad/quad1.jpg';
import quad2 from '../images/quad/quad2.jpg';
import quad3 from '../images/quad/quad3.jpg';
import quad4 from '../images/quad/quad4.jpg';
import quad5 from '../images/quad/quad5.jpg';
import quad7 from '../images/quad/quad7.jpg';
import quad9 from '../images/quad/quad9.jpg';
import quad10 from '../images/quad/quad10.jpg';

import sauna1 from '../images/sauna/sauna1.jpg';
import sauna2 from '../images/sauna/sauna2.jpg';
import sauna3 from '../images/sauna/sauna3.jpg';
import sauna4 from '../images/sauna/sauna4.jpg';
import sauna5 from '../images/sauna/sauna5.jpg';

import yard1 from '../images/yard/yard1.jpg';
import yard2 from '../images/yard/yard2.jpg';
import yard3 from '../images/yard/yard3.jpg';
import yard4 from '../images/yard/yard4.jpg';
import yard5 from '../images/yard/yard5.jpg';
import yard6 from '../images/yard/yard6.jpg';
import yard8 from '../images/yard/yard8.jpg';
import yard9 from '../images/yard/yard9.jpg';
import yard11 from '../images/yard/yard11.jpg';

import boat1 from '../images/boat/boat1.jpg';
import boat2 from '../images/boat/boat2.jpg';
import boat3 from '../images/boat/boat3.jpg';
import boat4 from '../images/boat/boat4.jpg';
import boat5 from '../images/boat/boat5.jpg';

import pool1 from '../images/pool/pool1.jpg';
import pool2 from '../images/pool/pool2.jpg';
import pool3 from '../images/pool/pool3.jpg';
import FridgeAmenity from '../models/houses/amenities/FridgeAmenity';
import HeatingAmenity from '../models/houses/amenities/HeatingAmenity';
import ShowerAmenity from '../models/houses/amenities/ShowerAmenity';
import TvAmenity from '../models/houses/amenities/TvAmenity';
import WcAmenity from '../models/houses/amenities/WcAmenity';
import AmenityPicker from '../models/houses/amenities/AmenityPicker';

const house1Urls = [
    house110,
    house12,
    house13,
    house16,
    house15,
    house17,
    house18,
    house19,
    house111,
    house11,
    house112,
    house14,
];

const house2Urls = [
    house25,
    house24,
    house22,
    house21,
    house27,
    house26,
    house28,
    house29,
    house212,
    house23,
    house211,
    house210,
    house213,
];

const house3Urls = [
    house38,
    house31,
    house34,
    house32,
    house33,
    house36,
    house35,
    house37,
];

const quad = [
    quad1,
    quad2,
    quad3,
    quad4,
    quad5,
    quad7,
    quad9,
    quad10,
];

const sauna = [
    sauna1,
    sauna2,
    sauna3,
    sauna4,
    sauna5,
];

const pool = [
    pool1,
    pool2,
    pool3,
];

const yard = [
    yard1,
    yard2,
    yard3,
    yard4,
    yard5,
    yard6,
    yard8,
    yard9,
    yard11,
];

const boat = [
    boat2,
    boat1,
    boat3,
    boat4,
    boat5,
];

const amenites = [
    new AmenityUrls("Łódki", boat),
    new AmenityUrls("Podwórko", yard),
    new AmenityUrls("Pojazdy", quad),
    new AmenityUrls("Balia ogrodowa", pool),
    new AmenityUrls("Sauna", sauna),
];

let amenitesAll = amenites.map(am => am.urls).flat(1);

function shuffle(array: string[]) {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

const handleScrollTo = (scrollTo: string) => {
    scroller.scrollTo(scrollTo, {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: -150
    });
};

interface MainPageProps {

}

const MainPage: React.FunctionComponent<MainPageProps> = () => {

    amenitesAll = [amenitesAll[0], ...shuffle(amenitesAll.slice(1))];

    const [landingImageUrl, setLandingImageUrl] = React.useState<string>("");
    const [houses, setHouses] = React.useState<House[]>([]);
    const [amenitiesUrls, setAmenitiesUrls] = React.useState<AmenityUrls[]>([]);

    function downloadImages() {
        function downloadHouseImages(newHouseDetails: House) {
            setHouses(
                houses => {
                    return [
                        ...(houses.filter(h => h.name !== newHouseDetails.name)),
                        newHouseDetails
                    ];
                }
            );
        }

        downloadHouseImages(
            new House({
                shortDesc: "Domek o powierzchni 40 \u33A1, wielosezonowy, z ogrzewaniem, pomieści 6 osób.",
                details: new DefaultHouseDetails({
                    amenities: [
                        new FridgeAmenity(),
                        new HeatingAmenity(),
                        new ShowerAmenity(),
                        new TvAmenity(),
                        new WcAmenity(),
                    ],
                    capacity: 6
                }),
                images: house1Urls,
                descriptionDetails: [
                    "- salon z kanapą (można dodatkowo rozłożyć do spania), telewizor z tv naziemną, stolik, kominek gazowy",
                    "- aneks kuchenny z lodówką, kuchenką, piekarnikiem, zlewem, czajnikiem oraz niezbędnymi akcesoriami do gotowania i spożywania posiłków",
                    "- jadalnia ze stołem i 4 krzesłami  ",
                    "- sypialnia z łóżkiem małżeńskim i pościelą, garderoba  ",
                    "- sypialnia z 2 łóżkami pojedynczymi i pościelą  ",
                    "- łazienka z prysznicem i wc (wejście do łazienki z przedpokoju i głównej sypialni)",
                ],
                descriptionDetailsHeader: 'Domek o powierzchni 40 \u33A1, wielosezonowy, z ogrzewaniem, pomieści 6 osób. Domek posiada:',
                name: "Domek nr 1"
            })
        );

        downloadHouseImages(
            new House({
                shortDesc: "Domek o powierzchni 40 \u33A1, wielosezonowy, z ogrzewaniem, pomieści 8 osób.",
                details: new DefaultHouseDetails({
                    amenities: [
                        new FridgeAmenity(),
                        new HeatingAmenity(),
                        new ShowerAmenity(),
                        new TvAmenity(),
                        new WcAmenity(),
                    ],
                    capacity: 8
                }),
                images: house2Urls,
                descriptionDetails: [
                    "- salon z kanapą(można dodatkowo rozłożyć do spania), telewizor z tv naziemną, kominek gazowy",
                    "- aneks kuchenny z lodówką, kuchenką, piekarnikiem, zlewem, czajnikiem oraz niezbędnymi akcesoriami do gotowania i spożywania posiłków",
                    "- jadalnia ze stołem i 4 krzesłami",
                    "- sypialnia z łóżkiem małżeńskim i pościelą",
                    "- sypialnia z 2 łóżkami pojedynczymi i pościelą",
                    "- sypialnia z 2 łóżkami pojedynczymi i pościelą",
                    "- łazienka z prysznicem(wejście do łazienki z przedpokoju i głównej sypialni)",
                    "- wc",
                ],
                descriptionDetailsHeader: 'Domek o powierzchni 40 \u33A1, wielosezonowy, z ogrzewaniem, pomieści 8 osób. Domek posiada:',
                name: "Domek nr 2"
            })
        );

        downloadHouseImages(
            new House({
                shortDesc: "Domek o powierzchni 40 \u33A1, wielosezonowy, z ogrzewaniem, pomieści 6 osób.",
                details: new DefaultHouseDetails({
                    amenities: [
                        new FridgeAmenity(),
                        new HeatingAmenity(),
                        new ShowerAmenity(),
                        new TvAmenity(),
                        new WcAmenity(),
                    ],
                    capacity: 6
                }),
                images: house3Urls,
                descriptionDetails: [
                    "- salon z kanapą(można dodatkowo rozłożyć do spania), telewizor z tv naziemną, stolik",
                    "- aneks kuchenny z lodówką, kuchenką, piekarnikiem, zlewem, czajnikiem oraz niezbędnymi akcesoriami do gotowania i spożywania posiłków",
                    "- jadalnia ze stołem i 4 krzesłami",
                    "- sypialnia z łóżkiem małżeńskim i pościelą",
                    "- sypialnia z 2 łóżkami pojedynczymi i pościelą",
                    "- łazienka z prysznicem i wc(wejście do łazienki z przedpokoju i głównej sypialni)",
                ],
                descriptionDetailsHeader: 'Domek o powierzchni 40 \u33A1, wielosezonowy, z ogrzewaniem, pomieści 6 osób. Domek posiada:',
                name: "Domek nr 3"
            })
        );

        setAmenitiesUrls(amenites);
        setLandingImageUrl(boat2);
    }

    const houseImgs = "houseImgs";
    const landingImg = "landingImg";
    const amenityImgs = "amenityImgs";

    React.useEffect(() => {

        const localStoredHouseImages = sessionStorage.getItem(houseImgs);
        const localStoredLandingImage = sessionStorage.getItem(landingImg);
        const localStoredAmenitiesImages = sessionStorage.getItem(amenityImgs);

        // When no data is stored download it!
        if (!localStoredHouseImages ||
            !localStoredLandingImage ||
            !localStoredAmenitiesImages) {
            console.log("No local storage");
            downloadImages();
            return;
        }

        try {

            const houseList: House[] | null = JSON.parse(localStoredHouseImages)?.map((elem: HouseProps) => {
                elem.details = new DefaultHouseDetails({
                    amenities: elem.details?.amenities.map(amenity => AmenityPicker.getAmenity(amenity.type)) ?? [],
                    capacity: elem.details?.capacity ?? 0
                });
                return new House(elem)
            });
            const landingImageUrl: string | null = JSON.parse(localStoredLandingImage);
            const amenityList: AmenityUrls[] | null = JSON.parse(localStoredAmenitiesImages);

            if (!houseList || !landingImageUrl || !amenityList) throw Error("Stored session data is missing!");

            console.log("found session storage");
            setAmenitiesUrls(amenityList);
            setHouses(houseList);
            setLandingImageUrl(landingImageUrl);

        } catch (e) {
            console.log("SessionStorage parse exception!");
            console.error(e);
        }

    }, []);

    // Save session storage
    React.useEffect(() => {
        console.log("value change");
        if (!sessionStorage.getItem(houseImgs) && houses.length > 0) {
            console.log("set houses");
            sessionStorage.setItem(houseImgs, JSON.stringify(houses));
        }
        if (!sessionStorage.getItem(landingImg) && landingImageUrl) {
            console.log("set landing");
            sessionStorage.setItem(landingImg, JSON.stringify(landingImageUrl));
        }
        if (!sessionStorage.getItem(amenityImgs) && amenitiesUrls.length > 0) {
            console.log("set amenities");
            sessionStorage.setItem(amenityImgs, JSON.stringify(amenitiesUrls));
        }
    }, [landingImageUrl, houses, amenitiesUrls]);

    return (
        <>
            <LandingSection amenitiesImageUrls={amenitesAll} scrollTo={handleScrollTo} />
            <AmenitiesSection amenitiesUrls={amenitiesUrls} />
            <OfferSection houses={houses} />
            <ContactSection />
        </>
    );
}

export default MainPage;